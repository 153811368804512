import axios from "axios";

const cSignInBaseApi = "https://signin-va.cloudcti.nl/signin/api/";

export async function SigninApiTokenGet(aUsername, aPassword) 
{
    return await axios.get(cSignInBaseApi + "token", {
        auth: {
            username: aUsername,
            password: aPassword
        }
    });
}

export async function SigninApiTokenRefresh(aCloudCTIToken) 
{
    return await axios.get(
        cSignInBaseApi + "tokenrefresh",  
        {
            headers:
            {
                Authorization: "Bearer " + aCloudCTIToken,
            },
        }
    )
}