import classes from './Main.module.css';
import '../General.css';
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react';
import BurgerMenu from './BurgerMenu.js'
import React from "react";

// https://www.npmjs.com/package/react-burger-menu
// https://dev.to/faisaljebali/how-to-build-a-sidebar-menu-in-react-with-react-burger-menu-5bnm
// Zoek voorbeelden op
var securityToken;
const versionNumber = 'v1.1';
var nrDefaultScripts = 0;

const Main = (props) => {
	securityToken = props.SecurityToken;
	const [matches, setMatches] = useState([]);
	const searchParams = new URLSearchParams(document.location.search);
	const phoneNumber = searchParams.get('phonenumber');
	const [matchHtml, setMatchHtml] = useState(<div></div>);

	useEffect (() => {
		const AsyncWork = async () => {
			const matches_temp = await LookUpNumber(phoneNumber);
			// const matches_temp = [{ApplicationName: 'VERY custom', Notification: ['Incoming call:', 'hehe: veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee eeeeeeeeeeeeeeeeeeeeeeeeeeeee eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeery long test', 'test:', 'breh'], RecognitionFields: {Uri:'https://google.com'}, RecognitionFieldTypes: {Uri:'Uri'}}];
			setMatches(matches_temp);
			const matchElements = CreateMatchElements(matches_temp);
			setMatchHtml(<div className={classes.matchContainer}>{matchElements}</div>);
		}

		AsyncWork(phoneNumber);
	}, []);

	return (
			<div className={classes.App}>
				<header className='foregroundElement'>
					<div className={classes.headerDiv}>
						<div className={classes.verticalCenter + ' ' + classes.buttonTheme}>
							<BurgerMenu></BurgerMenu>
						</div>
						<div className={classes.verticalCenter + ' ' + classes.headerText}>
							<p>
								Found {matches.length - nrDefaultScripts} matches for {phoneNumber}					
							</p>
						</div>
					</div>
				</header>
				<div className={classes.AppBody}>
					<div className={classes.scrollContainer + ' foregroundElement'}>
						{matchHtml}
					</div>
				</div>
				<div className={classes.version}>
					<p>
						{versionNumber}
					</p>
				</div>
			</div>
	);
}

async function LookUpNumber(phoneNumber_p) {
	if (!phoneNumber_p)
		return [];

	console.log('looking up phonenumber..');
	var result = await(fetch(`https://lookup-va.cloudcti.nl/lookup/api/lookupnumber?number=${phoneNumber_p}&returnintegrations=True`, 
	{
		headers: new Headers({
			"Authorization": `Bearer ${securityToken}`
		}),
	})
	.then(response => {
		if (!response.ok) 
			throw new Error(response);
		return response.json();
	})
	.catch((error) => {
		console.log(error);
	}));

	if (result.Matches) {
		console.log('retrieved matches');
		return result.Matches;
	}
	else
		throw Error(result.Exception);
}

function CreateMatchElements(matches_temp) {
	var matchNotifications = [];
	for (var i = 0; i < matches_temp.length; i++) {
		const match = matches_temp[i];
		
		var scripts = match.Scripts;
		// if (scripts.length > 0) {
		// 	const el = scripts[0];
		// 	scripts = [el, el, el, el, el];
		// }
		var scriptButtons = [null, null, null, null, null];
		for	(var j = 0; j < scripts.length; j++) {
			const script = scripts[j];
			const url = script.Settings.WebPage;
			const name = script.Name;
			if (script.Type == 'OpenWebPage')
				scriptButtons[j] = <div key={`buttonDiv${i}.${j}`}><button onClick={() => window.open(url)}><p>{name}</p></button></div>;
			else
				scriptButtons[j] = 
				<div className={classes.buttonDisabled + ' ' + classes.tooltip}>
					<button><p>{name}</p></button>
					<span className={classes.tooltiptext}>Screenpop executor required</span>
				</div>;
		}

		const buttonTable = 
		<table className={classes.buttonTable + ' ' + classes.verticalCenter}>
			<tbody>
				<tr>
					<td>{scriptButtons[2]}</td>
					<td>{scriptButtons[1]}</td>
					<td>{scriptButtons[0]}</td>
				</tr>
				<tr>
					<td>{null}</td>
					<td>{scriptButtons[4]}</td>
					<td>{scriptButtons[3]}</td>
				</tr>
			</tbody>
		</table>

		var notifMessage;
		var notifMessageFormatHtml = [];
		if (match.IntegrationGuid == "") { // Default script
			var notifMessage = "Default script(s)";
			notifMessageFormatHtml.push(<p key={`${i}.${0}`}>{notifMessage}</p>);
			for (var j = 1; j < 4; j++)
				notifMessageFormatHtml.push(<p key={`notifM${i}.${j}`}><br/></p>);

			nrDefaultScripts += 1;
		}
		else 
		{
			notifMessage = match.Notification;
			notifMessage.shift(); // Remove first line (always 'Incoming call')
			for (var j = 0; j < notifMessage.length; j++)
				notifMessageFormatHtml.push(<p key={`notifM${i}.${j}`}>{notifMessage[j]}</p>);		
		}
		
		matchNotifications.push(
			<div key={i} className={classes.matchElement}>
				<div className={classes.matchText}>
					{notifMessageFormatHtml}
				</div>
				{buttonTable}
			</div>
		);
		if (i != matches_temp.length - 1) // Don't put a divider after the last element
			matchNotifications.push(
				<div className={classes.dividerLine} key={`divider${i}`}></div>
			);		
	};
	return matchNotifications;
}

Main.propTypes = {
    SecurityToken: PropTypes.string
}

export default Main;
