export function SetThemeCss() {
	var r = document.querySelector(':root');
	var background_color, button_color, button_border_color, button_hover_color, 
	button_disabled_color, button_disabled_border_color, text_color, text_hover_color, 
	blade_color, burger_cross_color, img_invert;
	button_border_color = '#0F9DDD';
	button_disabled_border_color = "#B1B1B1";
	text_hover_color = '#B1B1B1';

	if (localStorage.getItem('theme') == 1) { // Dark mode
		background_color = '#1F1F1F';
		button_color = '#292929';
		button_hover_color = '56,56,56';
		button_disabled_color = '#292929';
		text_color = '#FFFFFF';
		blade_color = '#292929';
		burger_cross_color = '#B2B2B2';
		img_invert = 1;
	}
	else { // Light mode
		background_color = '#F3F3F3';
		button_color = '#FFFFFF';
		button_hover_color = '244,244,244';
		button_disabled_color = '#FFFFFF';
		text_color = '#171B2C';
		blade_color = 'white';
		burger_cross_color = '#10131F';
		img_invert = 0;
	}

	r.style.setProperty('--background_color', background_color);
	r.style.setProperty('--button_color', button_color);
	r.style.setProperty('--button_border_color', button_border_color);
	r.style.setProperty('--button_hover_color', button_hover_color);
	r.style.setProperty('--button_disabled_color', button_disabled_color);
	r.style.setProperty('--button_disabled_border_color', button_disabled_border_color);
	r.style.setProperty('--text_color', text_color);
	r.style.setProperty('--text_hover_color', text_hover_color);
	r.style.setProperty('--blade_color', blade_color);
	r.style.setProperty('--burger_cross_color', burger_cross_color);
	r.style.setProperty('--img_invert', img_invert);
}