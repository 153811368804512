import React, { useState } from "react"
import Main from "./Components/Main"
import { Login, StoreToken, GetStoredToken, RemoveStoredToken, GetStoredTokenExp } from './Components/Login'
import { useTeamsFx } from "@microsoft/teamsfx-react"
import { SigninApiTokenRefresh } from "./Components/SigninApi"

const App = (props) => {  
  const [mReload, SetReload] = useState(false)

  const [mCloudCTIToken, SetCloudCTIToken] = useState(null)

  // const oldCloudCtiToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblBhc3N3b3JkIjoiNUptcVJTVmhmZ1VXSGd3K2U4L3UwV1NXWVQrVDh3NTFHL3FHZC8zcm5xVXdrWit4M0sxbEhqYXR6UWhvbVd0b0NSRDNqZjl3aEwxQnVKVzVNY25CSzM2YlJtT3NXakk1TlVOaHp0TnQ0ckhyZW5KK21LdWFSNG15MDlSdlNLOEMiLCJTaWduaW5BcHBsaWNhdGlvbiI6IlRlYW1zIiwiVGVuYW50R3VpZCI6IlRlbmFudF80M2Y0YjUzMy1mOTg3LTQzODAtYmNhZi00MGNkNzYxMGFmYmIiLCJUZW5hbnROYW1lIjoiQ2xvdWRDVEkgSFEiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfOTFlMGRjOTQtMTExZi00YjNmLTlkMWUtM2FmMjBmNTU4M2RhIiwiVXNlck5hbWUiOiJKZXJvZW4iLCJVc2VySXNBZG1pbiI6IjEiLCJFbWFpbCI6Implcm9lbkBjbG91ZGN0aS5ubCIsIlJlY29nbml0aW9uQW5kU2NyZWVucG9wIjoiMSIsIlJvbGVzIjoiVXNlcixDYWxsQ29udHJvbCxUZWFtcyxUZWFtcyIsImV4cCI6MTcxNjA0MzQ3NCwiaXNzIjoic2lnbmluLXZhIiwiYXVkIjoiQ2xvdWRDVEkyMDIyIn0.yNpjh3W07cV5INnL9l-7Whp5gU1yRFachOdv0kh_9fI'
  // TODO: test refresh with this ^^^
  async function ValidateTokenExpiration(aToken) 
  {
		const expDate = GetStoredTokenExp();
		const daysToExpiry = Math.floor((expDate - Date.now()) / (1000*60*60*24))

    //Check token expiration
    if (daysToExpiry >= 7) 
    {
      //token is valid and will remain valid for at least 7 days
      return aToken
    }
    else if (daysToExpiry > 0) 
    {      
      //token will expire within 7 days
      try
      {
        const response = await SigninApiTokenRefresh(aToken)
        const data = response.data
        if (data && (data !== undefined) && data.SecurityToken && (data.SecurityToken !== undefined))
        {
          StoreToken(data)
          return data.SecurityToken
        }
        else
        {
          return aToken
        }
      }
      catch (error)
      {
        //Token is still valid
        return aToken
      } 
    }
    else
    {
      //Token has expired. Sign out user by removing local storage.
      RemoveStoredToken()
      return ""
    }
  }

  async function GetCloudCTIToken()
  {
    let storedToken = GetStoredToken()
    let CloudCTIToken = null
    if (storedToken !== null)
      CloudCTIToken = await ValidateTokenExpiration(storedToken)

    SetCloudCTIToken(CloudCTIToken !== null ? CloudCTIToken : "")
    SetReload(!mReload)
  }
  

  // if (!useTeamsFx().inTeams) {
  //   return <div align="center">This page can only be viewed as an app within Microsoft Teams</div>
  // }
  // else 
  {
    if (mCloudCTIToken === null)
    {
      GetCloudCTIToken()
      return <div align="center"><br/>Loading....</div>
    }
    else if (mCloudCTIToken === "")
    {
      return (<Login/>)
    }
    else
    {
      return (<Main SecurityToken={mCloudCTIToken}/>)
    }
  }
}

export default App