import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import { SigninApiTokenGet } from "./SigninApi"

import classes from "./Login.module.css";

import CloudCtiPng from '../Assets/CloudCTI.png';
import UserPng from '../Assets/user.png';
import PasswordPng from '../Assets/password.png';

import { jwtDecode } from "jwt-decode";

export function Login(props) {
  const [mUser, SetUser] = useState(null)
  const [mPassword, SetPassword] = useState("")
  const [mErrorMessage, SetErrorMessage] = useState(props.Error)
    
  const SignIn = async (aUsername,aPassword) => {
    if (!aUsername || !aPassword) {
        SetErrorMessage(`Missing username or password`);
        return;
    }
    try 
    {
        var signinResult = await SigninApiTokenGet(aUsername,aPassword);
        if (signinResult.data)
        {
            if (signinResult.status === 200)
            {
                if (signinResult.data.SecurityToken)
                {
                    StoreToken(signinResult.data);
                    window.location.reload();
                }
            }
            else
            {
                let error = "";
                try { error = signinResult.data.Exception; } catch {}
                SetErrorMessage(error === "" ? "An error has occurred" : error);
            }
        }
        else
        {
            SetErrorMessage("An error has occurred");            
        }
    } 
    catch (ex) 
    {    
        let error = ex;
        try { error = ex.response.data.Exception; } catch {}
        SetErrorMessage(error === "" ? "An error has occurred" : error);
    }
  }

  return (   
    <table className={classes.main}>
        <tbody>
            <tr>
                <td align="center" valign="center" className={classes.errormessage}>
 
                    <table width="400" className={classes.login + ' foregroundElement'} cellPadding="12">
                        <tbody>
                            <tr>
                                <td align="center"><img alt="" src={CloudCtiPng}/></td>
                            </tr>
                            <tr>
                                <td align="center" className={classes.header}><p>Enter your CloudCTI credentials</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding="1" width="95%">
                                    <tbody>
                                        <tr height="8" className={classes.bordertop}/>
                                        <tr>
                                            <td>
                                                <img alt="" src={UserPng} width="20" height="20" className={classes.loginFieldIcon}/> &nbsp; <input className={classes.signininputfield} type="text" value={mUser === null ? ("") : mUser} onChange={evt => SetUser(evt.target.value)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img alt="" src={PasswordPng} width="20" height="20" className={classes.loginFieldIcon}/> &nbsp; <input className={classes.signininputfield} type="password" value={mPassword} onChange={evt => SetPassword(evt.target.value)} onKeyPress={(evt) => { if (evt.key === 'Enter') { SignIn(mUser,mPassword,"")}}}/>
                                            </td>
                                        </tr>
                                        {mErrorMessage ? <tr className={classes.errormessage}><td align="center">{mErrorMessage}</td></tr> : <tr height="0"/>}
                                    </tbody>
                                    </table>

                                </td>
                            </tr>
                            <tr>
                                <td align="center"><Button variant="primary" size="sm" onClick={() => SignIn(mUser,mPassword,"")} className={classes.signinbutton}><p>Sign In</p></Button></td>
                            </tr>
                            {/* <tr>
                                <td align="center">or</td>
                            </tr>
                            <tr>
                                <td align="center"><Button variant="info" size="sm" onClick={() => SignIn("","","Microsoft")} className={classes.signinbutton+>Authorize with Microsoft</Button></td>                                
                            </tr> */}
                        </tbody>
                    </table>

                </td>
            </tr>
        </tbody>
    </table>     
  );
}

export function StoreToken(data) {
    localStorage.setItem("securityTokenCCTI0", data.SecurityToken)
}

export function GetStoredToken() {
    const token = localStorage.getItem('securityTokenCCTI0');
    return token
}

export function GetStoredTokenExp() {
    const token = localStorage.getItem('securityTokenCCTI0');
    const exp = jwtDecode(token).exp;
    const expireDate = new Date(exp * 1000); // .exp is in seconds, dates are in ms
    return expireDate;
}

export function RemoveStoredToken() {
    localStorage.removeItem('securityTokenCCTI0')
}