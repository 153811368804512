import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './General.css';
import { SetThemeCss } from './Theme.js';

// import Main from './Components/Main';
import ScreenChooser from './ScreenChooser';

SetThemeCss();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> // <- causes the component to render twice in development, react reasons that it helps catch bugs
  <ScreenChooser />
  // </React.StrictMode>
);
